*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    height: 100vh;
    font-family: 'Helvetica';
    color: white;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.container {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: grid;
    place-content: center;
}


.content {
    display: flex;
    gap: 5em;
    width: 100%;
    padding-top: 3em;
    position: relative;
}

.content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid white;
    transform: scaleX(1);
}


h1 {
    font-size: 3.5rem;
    width: 50vw;
    line-height: 97%;
    text-align: right;
}

h1, p {
    flex-basis: 0;
    flex-grow: 1;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

p {
    font-size: 1.3rem;
    width: 40vw;
    line-height: 180%;
}

a {
    font-size: 1.3rem;
    width: 40vw;
    color: rgb(255, 255, 255);
}

a:hover {
    color: rgb(0, 255, 255);

}

div#footer2 {
    position:absolute;
    left: 15px;
    bottom: 15px;
    font-size: 0.5rem; 
    width: 30vw;
    color: rgb(184, 184, 184);    
  }

  div#a {
    font-size: 10px;
  }

  div#a:hover {
    color: rgb(0,255,255);
}